<template>
  <q-dialog v-model="dialogState" position="bottom" maximized @hide="resetData">
    <q-card
      class="mobileCardDialogue searchstore-dialog"
      style="max-height: 100dvh"
    >
      <div class="dialogHeader">
        <q-btn class="close absolute-top-right" padding="md" flat v-close-popup>
          <SvgIcon icon="close" size="14px" />
        </q-btn>
        <div class="container">
          <div class="row no-wrap">
            <div class="col q-px-sm">
              <h4
                class="text-h6 text-black text-center searchstore-dialog-title"
              >
                {{
                  properties.dialogTitle
                    ? properties.dialogTitle
                    : 'How would you like your order?'
                }}
              </h4>
            </div>
          </div>
        </div>
      </div>

      <SearchLocation
        on-dialog
        :target-fulfillment="properties.targetFulfillment"
      />
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: 'changeLocation',
    }
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
  },
  components: {
    SearchLocation: () => import('components/location/SearchLocation'),
  },
  methods: {
    resetData() {
      this.deleteDialogProperties(this.dialog)
    },
  },
}
</script>
<style lang="scss">
.searchstore-dialog-title {
  margin-bottom: 10px;
  @media (min-width: 767px) {
    margin-bottom: 20px;
  }
  @media (min-width: 1680px) {
    margin-bottom: 25px;
  }
}
</style>
